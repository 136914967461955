import React from "react";

export default function Alert(props) {
  return (
    <div className="alert-container">
      <div className="alert">
        {props.text}
        <div className="alert-actions">
          <button onClick={props.action}>OK</button>
          {props.cancel && (
            <button
              style={{
                marginLeft: "10px",
                backgroundColor: "#8c8c8c",
              }}
              onClick={props.cancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
